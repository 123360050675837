import React  from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import BloqueContacto from "../components/bloque-contacto"

import { icon_servicios, icon_desarrollo, icon_licenciamento } from '../images/expertos';
import { default as formacion } from '../images/formacion/img-formacion.svg';
import { default as soporte } from '../images/soporte/img-soporte.png';
import { default as migracion } from '../images/migracion-versiones/img-migracion.svg';
import { default as tuning_gestores } from '../images/gestores/img-tuning.png';


const InformixPage = () => {


	return (
		<Layout className="page-informix">
			<SEO 
                title="IBM Informix - Expertos en soporte, formación y consultoría" 
                description="Contratos de soporte Informix 24x7, Outsourcing, Soporte bajo demanda, Formación, Migración de nuevas versiones, Tuning de gestores, Desarrollo 4GL"
                />

			<section className="bloque bloque-featured">
			    <div className="container">
			        <div className="bloque-header">
			            <h1>Servicios de IBM Informix</h1>
			            <h2>Servicios formacion, soporte y consultoria en Informix</h2>
			        </div>
                    <Link className="btn btn-accent" title="Contacta con DMS" to="/contacto">Contacto</Link>
			    </div>
			</section>

            <section className="bloque-expertos">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title"> Somos expertos en IBM Informix</h2>
                        <p className="info">
                            Nuestro centro de Soporte 24×7 ubicado en Barcelona está formado por especialistas certificados en IBM Informix capacitados para ofrecer soporte, formación y consultoría.
                        </p>
                    </div>

                    <div className="expertos row align-items-center justify-content-center">
                        <div className="col-lg-4 expertos-item">
                            <span className="expertos-img">
                                <img src={icon_servicios} alt="servicios" />
                            </span>
                            <h3>Servicios</h3>
                            <p className="expertos-info">Ofrecemos cursos oficiales de IBM Informix y formación a medida sobre administración, HDR, migraciones o nuevas funcionalidades. Si necesitas Soporte o ayuda para mejorar tus gestores, DMS es tu partner ideal</p>
                        </div>
                        <div className="col-lg-4 expertos-item">
                            <span className="expertos-img">
                                <img src={icon_licenciamento} alt="Licenciamiento" />
                            </span>
                            <h3>Licenciamiento</h3>
                            <p className="expertos-info">DMS es un partner de IBM especializado en Informix, si necesitas ayuda en licenciamiento o vas a renovar un mantenimiento ponte en contacto con nosotros y te ayudaremos.</p>
                        </div>
                        <div className="col-lg-4 expertos-item">
                            <span className="expertos-img">
                                <img src={icon_desarrollo} alt="Desarrollo" />
                            </span>
                            <h3 >Desarollo</h3>
                            <p className="expertos-info">Somos expertos desarrollando en 4GL y en migración a Genero. Si necesitas programadores o soporte para tu equipo de desarrollo contacta con nosotros.</p>
                        </div>
                    </div>
                </div>

            </section>

            <section className="bloque-formacion">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">Formación a medida</h2>
                    </div>
                    <div className="row align-items-center justify-content-center formacion">
                        <div className="info col-xl-7 order-2 order-xl-1">
                            <p>
                                IBM sigue apostando por Informix y en cada nueva versión incorpora importantes funcionalidades. Por este motivo, es importante estar al día para aprovechar al máximo las nuevas capacidades del gestor.  
                            </p>
                            <p>
                                Nuestros cursos de formación cubren todas las áreas de Informix, desde la administración básica hasta tuning pasando por HDR y optimización de consultas SQL.  
                            </p>

                            <div className="btn-container">
                                <Link activeClassName="active" className="btn btn-accent" title="Contacta con DMS" to="/contacto">Contactar</Link>
                            </div>

                        </div>
                        <div className="informix-img col-xl-4 order-1 order-xl-2">
                            <img src={formacion} alt="formacion" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bloque-soporte">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">Soporte 24x7 Onsite y Offsite</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="soporte-img col-lg-6">
                            <img src={soporte} alt="soporte" />
                        </div>
                        <div className="info col-lg-6">
                            <p>
                                Somos unos enamorados de IBM Informix y gran parte del equipo está vinculado al gestor de bases de datos desde sus inicios.  
                            </p>
                            <p>
                                Ofrecemos contratos de Soporte y Mantenimiento personalizados con distintos SLA. También ofrecemos bolsas de horas para ayudarte en tareas de migración o de administración.  
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bloque-migracion">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">Migración de versiones</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 migracion-info order-lg-1 order-2">
                            <p>
                                Tenemos una gran experiencia en proyectos de migración de versiones viejas a últimas versiones y también en cambios de arquitectura Hardware.  
                            </p>
                            <p>
                                Ofrecemos servicios «hands-on» o si lo prefieres podemos definir la mejor estrategia de migración para tu empresa para que tu equipo la pueda llevar a cabo.  
                            </p>
                        </div>
                        <div className="col-lg-6 migracion-img order-lg-2 order-1">
                            <img src={migracion} alt="migracion" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bloque-gestores">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">Tuning de gestores</h2>
                    </div>

                    <div className="info">
                        <div className="gestores-img">
                            <img src={tuning_gestores} alt="tuning-gestores" />
                        </div>
                        <p className="gestores-info"> Si estás teniendo problemas de capacidad o el rendimiento de tu gestor se ha degradado podemos hacer un estudio para determinar los cuellos de botella y definir la mejor estrategia a seguir.  </p>
                        <Link className="btn btn-accent" to="/contacto">Contactar</Link>
                    </div>
                </div>
            </section>


            <BloqueContacto />

		</Layout>
	);
};

export default InformixPage
